<template>
  <section id="zones-section">
    <b-overlay
      :show="show"
      variant="transparent"
      :opacity="1.00"
      :blur="'2px'"
      rounded="lg"
    >
      <b-row class="mb-5">
        <b-col
          md="9"
          class="pb-3 pb-md-0"
        >
          <template>
            <h1>{{ $t("zones.title") }}</h1>
            <h5 class="text-primary">
              {{ $t("zones.subtitle") }}
            </h5>
          </template>
        </b-col>
        <b-col
          md="3"
          class="text-right"
          align-self="end"
        >
          <b-button
            variant="primary"
            @click="opModal('zones-modal', 0)"
          >
            <feather-icon
              icon="FilePlusIcon"
              size="14"
            />
            {{ $t("zones.text") }}
          </b-button>
        </b-col>
      </b-row>
      <b-row
        v-if="hasZone"
        class="mb-2"
      >
        <b-col>
          <b-form-input
            v-model="search"
            :placeholder="$t('Search')"
            @change="searchData"
          />
        </b-col>
        <b-col md="1">
          <b-button
            variant="primary"
            @click.stop.prevent="searchData"
          >
            <span style="vertical-align: text-top">{{ $t('Search') }}</span>
          </b-button>
        </b-col>
      </b-row>
      <b-row v-if="!show">
        <b-col lg="12">
          <b-table
            v-if="hasZone"
            :fields="fields"
            :items="itemList"
            show-empty
            :empty-text="$t('resultNotFound')"
            sticky-header="500px"
          >
            <template #cell(name)="data">
              <span>{{ data.item.node.name }}</span>
            </template>
            <template #cell(countries)="data">
              <span>{{ data.item.node.countries.totalCount }}</span>
            </template>
            <template #cell(categories)="data">
              <span>{{ data.item.node.categorycountrygroupSet.totalCount }}</span>
            </template>
            <template #cell(contents)="data">
              <span>{{ data.item.node.contentcountrygroupSet.totalCount }}</span>
            </template>
            <template #cell(urlCdn)="data">
              <div
                id="acortarPadre"
                class="align-items-center"
              >
                <span
                  id="acortar"
                >
                  {{ data.item.node.urlCdn }}
                </span>
              </div>
            </template>
            <template #cell(distributionId)="data">
              <span>{{ data.item.node.distributionId }}</span>
            </template>
            <template #cell(actions)="data">
              <div class="d-flex align-items-center">
                <b-dropdown
                  variant="link"
                  no-caret
                  toggle-class="p-0"
                  dropleft
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="17"
                      class="align-middle text-body"
                    />
                  </template>
                  <b-dropdown-item @click.prevent="editZone(data.item.node.id)">
                    <div class="d-flex">
                      <feather-icon
                        icon="Edit2Icon"
                        class=" mr-2"
                        size="18"
                      />
                      {{ $t('dataGeneric.edit') }}
                    </div>
                  </b-dropdown-item>
                  <b-dropdown-item @click.prevent="deleteZone(data.item.node.id)">
                    <div class="d-flex">
                      <feather-icon
                        icon="DeleteIcon"
                        size="18"
                        class=" mr-2"
                      />
                      {{ $t('dataGeneric.delete') }}
                    </div>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </template>
          </b-table>
          <b-jumbotron
            v-else
            bg-variant="transparent"
            border-variant="primary"
            :header="$t('zones.header')"
          >
            <b-row>
              <b-col>
                <p class="text-primary ">
                  {{ $t("zones.noExist") }}
                </p>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-button
                  variant="success"
                  @click="opModal('zones-modal', 0)"
                >
                  {{ $t("dataGeneric.create") }}
                </b-button>
              </b-col>
            </b-row>
          </b-jumbotron>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="mt-1">
          <b-pagination
            v-if="rows > perPage"
            v-model="currentPage"
            :total-rows="rows"
            :per-page="perPage"
            @input="getData"
          />
        </b-col>
      </b-row>
      <b-modal
        ref="zones-modal"
        size="xl"
        centered
        hide-footer
        :title="$t('zones.text')"
        @hidden="hide()"
      >
        <form-zone
          :id-zone="idZone"
          @return="hide()"
        />
        <b-button
          class="mt-1"
          variant="outline-danger"
          block
          @click.stop.prevent="opModal('zones-modal', 1)"
        >
          {{ $t("dataGeneric.close") }}
        </b-button>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>

import 'leaflet/dist/leaflet.css'
import { showToast, messageError } from '@/store/functions'

import {
  BRow,
  BCol,
  BModal,
  BTable,
  VBTooltip,
  BButton,
  BFormInput,
  BJumbotron,
  BOverlay,
  BDropdown,
  BDropdownItem,
  BPagination,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import axios from '@axios'

import { getUserData } from '@/auth/utils'
import FormZone from './FormZone.vue'

export default {
  components: {
    BRow,
    BFormInput,
    BButton,
    BCol,
    BTable,
    FormZone,
    BOverlay,
    BModal,
    BDropdown,
    BPagination,
    BDropdownItem,
    BJumbotron,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,

  },
  data() {
    return {
      zonesList: [],
      userData: getUserData(),
      show: false,
      fields: [
        { key: 'name', label: this.$t('dataGeneric.name') },
        { key: 'countries', label: this.$t('countries') },
        { key: 'categories', label: this.$t('Categorias') },
        { key: 'contents', label: this.$t('Contents') },
        { key: 'urlCdn', label: this.$t('url') },
        { key: 'distributionId', label: this.$t('distributionId') },
        { key: 'actions', label: this.$t('zones.label4') },
      ],
      isoCountries: [],
      mapPreview: null,
      isoCountriesSelected: [],
      hasZone: false,
      geojson: {},
      center: [47.31322, -1.319482],
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      nameZone: '',
      nameState: null,
      idZone: null,
      currentPage: 1,
      rows: 0,
      perPage: 15,
      search: '',
    }
  },
  computed: {
    itemList() {
      return this.zonesList
    },

  },
  mounted() {
    this.getData()
  },
  methods: {
    hide() {
      this.getData()
      this.opModal('zones-modal', 1)
    },
    editZone(id) {
      this.idZone = id
      this.opModal('zones-modal', 0)
    },
    deleteZone(id) {
      this.$swal({
        title: this.$t('code.title'),
        text: this.$t('deleteZone'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('code.confirm'),
        cancelButtonText: this.$t('dataGeneric.cancel'),
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axios
            .post('', {
              query: `mutation{ 
                deleteCountryGroup(id:"${id}"){
                  found
                }
            }`,
            }).then(res => {
              messageError(res, this)
              showToast(this.$t('success'), 1, this)
              this.getData()
            }).catch(() => {
              showToast(this.$t('error'), 2, this)
            })
        }
      }).catch(() => {

      })
    },
    opModal(type, op) {
      if (op === 0) {
        this.$refs[type].show()
      } else {
        this.$refs[type].hide()
        this.idZone = null
      }
    },
    searchData() {
      this.currentPage = 1
      this.getData()
    },
    getData() {
      this.show = true
      const query = `
        query {
          countryGroup(
          name:"${this.search}"
          client:"${this.userData.profile.client.id}",
          first:${this.perPage},
          offset:${this.perPage * (this.currentPage - 1)},
          ) {
            totalCount
            edges {
              node {
                id
                name
                urlCdn
                distributionId
                countries {
                  totalCount
                  edges {
                    node {
                      id
                    }
                  }
                }  
                categorycountrygroupSet {
                  totalCount
                }
                contentcountrygroupSet {
                  totalCount
                }              
              }
            }
          }
          platfomZones:countryGroup{
            totalCount
          }
        }
      `
      const response = axios.post('', { query })
      response.then(res => {
        messageError(res, this)
        this.zonesList = res.data.data.countryGroup.edges
        this.rows = res.data.data.countryGroup.totalCount
        this.hasZone = res.data.data.platfomZones.totalCount > 0
        this.show = false
      })
      response.catch(() => {
        showToast(this.$t('code.errorfetch'), 2, this)
        this.show = false
      })
    },

  },
}
</script>
<style lang="scss" scoped>

#zones-section .scroll-code {
  overflow-y: scroll;
  height: 221px;
}

#zones-section #acortar {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

#zones-section #acortarPadre {
  width: 500px;
  overflow: hidden;
  white-space: nowrap;
}
</style>
