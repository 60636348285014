<template>
  <div id="FormZone">
    <validation-observer
      ref="categories"
      tag="form"
      @submit.stop.prevent="handleSubmit"
    >
      <b-overlay
        :show="show"
        variant="transparent"
        :opacity="1.00"
        :blur="'2px'"
        rounded="lg"
      >
        <b-row class="mt-2 mb-2">
          <b-col class="">
            <h3>
              {{ $t('configZone') }}
            </h3>
            <h6 class="text-primary">
              {{ $t('accessControl.configure') }}
            </h6>
          </b-col>
          <b-col style="text-align: end;">
            <b-button
              variant="success"
              @click="handleSubmit"
            >
              {{ $t('dataGeneric.save') }}
            </b-button>
          </b-col>
        </b-row>
        <b-tabs>
          <b-tab active>
            <template #title>
              {{ $t('editContent.title2') }}
            </template>
            <b-row>
              <b-col>
                <validation-provider
                  v-slot="{ errors }"
                  name="name-input"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('firstName') + ' *'"
                    label-for="name-input"
                    :invalid-feedback="$t('required')"
                    :state="errors.length > 0 ? false : null"
                  >
                    <b-form-input
                      id="name-input"
                      v-model="nameZone"
                      :placeholder="$t('firstName')"
                      :state="errors.length > 0 ? false : null"
                      maxlength="150"
                      required
                    />
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col
                v-if="!isActive"
                md="3"
                style="align-self: center;"
              >
                <b-form-checkbox
                  v-model="isActive"
                  switch
                >
                  {{ $t('dataGeneric.activeQ') }}
                </b-form-checkbox>
              </b-col>
            </b-row>
            <b-row>
              <b-col>
                <b-form-group
                  :label="$t('urlCDN')"
                  label-for="url-input"
                >
                  <b-form-input
                    id="url-input"
                    v-model="urlCdn"
                    :placeholder="$t('url')"
                    type="url"
                    maxlength="250"
                  />
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group
                  :label="$t('distributionId')"
                  label-for="distributionId-input"
                >
                  <b-form-input
                    id="distributionId-input"
                    v-model="distributionId"
                    :placeholder="$t('distributionId')"
                  />
                </b-form-group>
              </b-col>
            </b-row>

            <b-overlay
              :show="overlay"
              variant="transparent"
              :opacity="1.00"
              :blur="'2px'"
              rounded="lg"
            >
              <b-form-group label-for="category-input">
                <b-row>
                  <b-col md="6">
                    <b-row>
                      <b-col>
                        <h6 class="text-primary font-weight-bold">
                          {{ $t("countries") }}
                          <small class="ml-2">
                            {{ totalCountries }}
                          </small>
                        </h6>
                      </b-col>
                    </b-row>
                    <b-row class="mt-21">
                      <b-col md="6">
                        <b-form-input
                          v-model="searchCountries"
                          :placeholder="$t('finalUsers.Country')"
                          @change="searchData"
                          @input="event => event.preventDefault"
                        />
                      </b-col>
                      <b-col
                        md="4"
                        class="d-flex align-items-center"
                      >
                        <feather-icon
                          v-show="rows > 0"
                          icon="PlusCircleIcon"
                          size="25"
                          class="text-success cursor-pointer ml-1"
                          @click="AddCount()"
                        />
                      </b-col>
                    </b-row>
                    <b-form-select
                      v-model="checkResults"
                      tag="ul"
                      multiple
                      :select-size="10"
                      class="scroll-list list-group list-group-flush  mt-2 border-0"
                    >
                      <b-form-select-option
                        v-for="c in isoCountry"
                        :key="c.node.id"
                        tag="li"
                        :value="c"
                        class="list-group-item"
                      >
                        <div class="d-flex">
                          <div class="ml-25">
                            <b-card-text class="mb-0 font-weight-bold">
                              {{ c.node.name }}
                            </b-card-text>
                          </div>
                        </div>
                      </b-form-select-option>
                    </b-form-select>
                    <b-col
                      cols="4"
                      class="mt-1"
                    >
                      <b-pagination
                        v-if="rows > perPage"
                        v-model="currentPage"
                        :total-rows="rows"
                        :per-page="perPage"
                        @input="getCountryIso"
                      />
                    </b-col>
                  </b-col>
                  <!-- PAISES SELECCIONADOS -->
                  <b-col md="6">
                    <b-row>
                      <b-col>
                        <h6 class="text-primary font-weight-bold">
                          {{ $t('selectCountries') }}
                          <small class="ml-2">
                            {{ totalSelected }} / {{ totalCountries }}
                          </small>
                        </h6>
                      </b-col>
                    </b-row>
                    <b-row class="mt-21">
                      <b-col md="6">
                        <b-form-input
                          v-model="searchTerm"
                          :placeholder="$t('finalUsers.Country')"
                          @change="searchSelected"
                        />
                      </b-col>
                      <b-col
                        md="4"
                        class="d-flex align-items-center"
                      >
                        <feather-icon
                          v-show="showSelected.length > 0"
                          icon="XCircleIcon"
                          size="20"
                          class="text-danger cursor-pointer ml-1"
                          @click="remCount()"
                        />
                      </b-col>
                    </b-row>
                    <div
                      id="selectRequired"
                      :class="{ 'required-state mt-1': isSelectRequiredInvalid }"
                    >
                      <b-form-select
                        v-model="checkMove"
                        tag="ul"
                        multiple
                        required
                        :select-size="10"
                        class="scroll-list list-group list-group-flush mt-2 border-0"
                      >
                        <b-form-select-option
                          v-for="cont in showSelected"
                          :key="cont.node.id"
                          tag="li"
                          :value="cont"
                          class="list-group-item"
                        >
                          <div class="d-flex ">
                            <div class="ml-25 ">
                              <b-card-text class="mb-0 font-weight-bold">
                                {{ cont.node.name }}
                              </b-card-text>
                            </div>
                          </div>
                        </b-form-select-option>
                      </b-form-select>
                    </div>
                    <validation-provider
                      v-slot="validationContext"
                      name="countries-input"
                      rules="required"
                    >
                      <b-form-group :state="validationContext.errors.length > 0 ? false : null">
                        <b-form-select
                          v-model="selectedCountries"
                          hidden
                          :state="validationContext.errors.length > 0 ? false : null"
                        />
                        <small class="text-danger">{{ requiredCountries(validationContext) }}</small>
                      </b-form-group>
                    </validation-provider>

                    <b-col
                      cols="4"
                      class="mt-1"
                    >
                      <b-pagination
                        v-if="rowsSelected > perPage"
                        v-model="currentPageSelected"
                        :total-rows="rowsSelected"
                        :per-page="perPage"
                        @input="paginate"
                      />
                    </b-col>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-overlay>
          </b-tab>
          <b-tab v-if="idZone">
            <template #title>
              {{ $t('code.C&C') }}
            </template>
            <b-card>
              <b-row>
                <b-col>
                  <b-card-header>
                    <span>{{ $t('categoriesZones') }}</span>
                  </b-card-header>
                  <b-list-group class="scroll-list">
                    <b-list-group-item
                      v-for="category in categorycountrygroupSet"
                      :id="category.node.category.id"
                      :key="category.node.id"
                      class="d-flex align-items-center cursor-pointer"
                      @click="routeToEditView(category.node.category.id, 'cat')"
                    >
                      <b-img
                        :src="buildImageUrl(category.node.category.imageUrl)"
                        class="tableImage"
                      />
                      <div>
                        <small>{{ category.node.category.name }}</small>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
                <b-col>
                  <b-card-header>
                    <span>{{ $t('contentsZones') }}</span>
                  </b-card-header>
                  <b-list-group class="scroll-list">
                    <b-list-group-item
                      v-for="content in contentcountrygroupSet"
                      :id="content.node.content.id"
                      :key="content.node.id"
                      class="d-flex align-items-center cursor-pointer"
                      @click="routeToEditView(content.node.content.id, 'cont')"
                    >
                      <b-img
                        :src="buildImageUrl(content.node.content.imageUrl)"
                        class="tableImage"
                      />
                      <div>
                        <small>{{ content.node.content.name }}</small>
                      </div>
                    </b-list-group-item>
                  </b-list-group>
                </b-col>
              </b-row>
            </b-card>
          </b-tab>
        </b-tabs>
      </b-overlay>
    </validation-observer>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BFormSelectOption,
  BFormSelect,
  BButton,
  BFormGroup,
  BFormInput,
  BTab,
  BTabs,
  BListGroup,
  BListGroupItem,
  BCardText,
  BFormCheckbox,
  BPagination,
  BOverlay,
  BImg,
  BCard,
  BCardHeader,
} from 'bootstrap-vue'
import { getUserData } from '@/auth/utils'
import axios from '@axios'
import {
  messageError, showToast, specialCategory,
} from '@/store/functions'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import configCategoryType from '@core/utils/categoryType'

const noCover = require('@/assets/images/backend/nocover.jpeg')

export default {
  components: {
    BFormCheckbox,
    BCardText,
    BRow,
    BButton,
    BCol,
    BListGroup,
    BListGroupItem,
    BTab,
    BTabs,
    BFormSelect,
    BFormSelectOption,
    BOverlay,
    BFormGroup,
    BPagination,
    BFormInput,
    ValidationProvider,
    ValidationObserver,
    BCard,
    BCardHeader,
    BImg,
  },
  props: {
    idZone: { default: null, type: String },
  },
  data() {
    return {
      specialCategory,
      required,
      categoryTypes: configCategoryType(true, false, false, false),
      userData: getUserData(),
      show: false,
      overlay: false,
      isoCountry: [],
      selectedCountries: [],
      checkResults: [],
      isActive: true,
      nameZone: '',
      urlCdn: '',
      distributionId: '',
      nameState: null,
      perPage: 30,
      dropdownVisible: false,
      currentPage: 1,
      searchCountries: '',
      rows: 0,
      checkMove: [],
      currentPageSelected: 1,
      rowsSelected: 0,
      showSelected: [],
      searchTerm: '',
      totalCountries: 0,
      totalSelected: 0,
      contentcountrygroupSet: [],
      categorycountrygroupSet: [],
      isSelectRequiredInvalid: false,

    }
  },

  mounted() {
    this.getTotalCountries()
    if (this.idZone) {
      this.fetchData()
    } else {
      this.getCountryIso()
    }
  },
  methods: {
    paginate() {
      const startIndex = (this.currentPageSelected - 1) * this.perPage
      const endIndex = startIndex + this.perPage
      this.showSelected = this.selectedCountries.slice(startIndex, endIndex)
    },
    AddCount() {
      if (this.checkResults.length === 0) {
        this.selectedCountries = [...this.selectedCountries, ...this.isoCountry]
      } else {
        this.selectedCountries = [...this.selectedCountries, ...this.checkResults]
      }
      this.showSelected = [...this.selectedCountries]

      this.rowsSelected = this.showSelected.length
      this.totalSelected = this.rowsSelected

      this.getCountryIso()
    },
    remCount() {
      let diferencial = []
      function sameObject(obj1, obj2) {
        return obj1.node.id === obj2.node.id
      }
      if (this.checkMove.length === 0) {
        diferencial = this.selectedCountries.filter(objeto1 => !this.selectedCountries.some(objeto2 => sameObject(objeto1, objeto2)))
      } else {
        diferencial = this.selectedCountries.filter(objeto1 => !this.checkMove.some(objeto2 => sameObject(objeto1, objeto2)))
      }

      this.selectedCountries = [...diferencial]
      this.showSelected = [...this.selectedCountries]
      this.rowsSelected = this.showSelected.length
      this.totalSelected = this.rowsSelected
      this.getCountryIso()
    },
    requiredCountries(data) {
      const keyExists = Object.keys(data.failedRules).includes('required')
      if (keyExists) {
        this.isSelectRequiredInvalid = true // Usar una propiedad de datos reactiva
        return this.$t('required')
      }
      this.isSelectRequiredInvalid = false // Usar una propiedad de datos reactiva
      return ''
    },
    searchData() {
      this.currentPage = 1
      this.getCountryIso()
    },
    searchSelected() {
      const searchTerm = this.searchTerm.toLowerCase()
      this.showSelected = this.selectedCountries.filter(item => item.node.name.toLowerCase().includes(searchTerm))
      this.rowsSelected = this.showSelected.length
    },
    updateZone() {
      this.show = true
      let countries = []
      if (this.selectedCountries.length !== 0) {
        countries = this.selectedCountries.map(objeto => objeto.node.id)
      }
      axios
        .post('', {
          query: `
          mutation($id:ID!, $name: String,$countries:[ID],$urlCdn: String,$distributionId: String){
            updateCountryGroup(id:$id,input:{
              name:$name,
              urlCdn:$urlCdn,
              distributionId:$distributionId,
              countries:$countries}){
              countryGroup{
                id
                name
              }
            }
          }
        `,
          variables: {
            name: this.nameZone,
            id: this.idZone,
            urlCdn: this.urlCdn,
            distributionId: this.distributionId,
            countries,
          },
        })
        .then(response => {
          messageError(response, this)
          this.$emit('return')
          showToast(this.$t('success'), 1, this)
          this.show = false
        }).catch(() => {
          showToast(this.$t('error'), 2, this)
          this.show = false
        })
    },
    createZone() {
      this.show = true
      let countries = []
      if (this.selectedCountries.length !== 0) {
        countries = this.selectedCountries.map(objeto => objeto.node.id)
      }
      axios
        .post('', {
          query: `
          mutation($name: String!,$client: ID!,$countries:[ID],$distributionId: String, $urlCdn: String){
            createCountryGroup(input:{
              name:$name,
              client:$client,
              urlCdn:$urlCdn,
              distributionId:$distributionId
              countries:$countries}){
              countryGroup{
                id
                name
                urlCdn
                distributionId
              }
            }
          }
        `,
          variables: {
            client: this.userData.profile.client.id,
            name: this.nameZone,
            urlCdn: this.urlCdn,
            distributionId: this.distributionId,
            countries,
          },
        })
        .then(response => {
          messageError(response, this)
          this.$emit('return')
          showToast(this.$t('success'), 1, this)
          this.show = false
        }).catch(() => {
          showToast(this.$t('error'), 2, this)
          this.show = false
        })
    },
    routeToEditView(id, type) {
      if (type === 'cat') {
        this.$router.push({ name: 'category-edit', params: { id } })
      } else {
        this.$router.push({ name: 'contents-edit', params: { id } })
      }
    },
    fetchData() {
      this.show = true
      axios
        .post('', {
          query: `
          {
            countryGroup(id:"${this.idZone}"){
              edges {
                node {
                  id
                  name
                  urlCdn
                  distributionId
                  countries {
                    edges {
                      node {
                        id
                        name
                      }
                    }
                  }
                  contentcountrygroupSet {
                    edges {
                      node {
                        id
                        content {
                          id
                          name
                          imageUrl
                        }
                      }
                    }
                  }
                  categorycountrygroupSet {
                    edges {
                      node {
                        id
                        category {
                          id
                          name
                          imageUrl
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        `,
        })
        .then(response => {
          messageError(response, this)
          this.nameZone = response.data.data.countryGroup.edges[0].node.name
          this.urlCdn = response.data.data.countryGroup.edges[0].node.urlCdn
          this.distributionId = response.data.data.countryGroup.edges[0].node.distributionId
          this.selectedCountries = response.data.data.countryGroup.edges[0].node.countries.edges
          this.contentcountrygroupSet = response.data.data.countryGroup.edges[0].node.contentcountrygroupSet.edges
          this.categorycountrygroupSet = response.data.data.countryGroup.edges[0].node.categorycountrygroupSet.edges
          this.showSelected = [...this.selectedCountries]
          this.rowsSelected = this.showSelected.length
          this.totalSelected = this.rowsSelected
          this.getCountryIso()
          this.show = false
        }).catch(() => {
          this.show = false
        })
    },
    buildImageUrl(folder) {
      return folder == null || folder.length === 0
        ? noCover
        : folder
    },
    getTotalCountries() {
      axios
        .post('', {
          query: `
          query {
            isoCountry{
              totalCount              
            }
          }
        `,
        })
        .then(response => {
          messageError(response, this)
          this.totalCountries = response.data.data.isoCountry.totalCount
        }).catch(() => {
        })
    },
    getElement(id = null, link = null) {
      if (id != null) {
        if (link === 'LIN') this.$router.push({ name: 'link-categories', params: { id, link: 'LIN' } })
        else { this.$router.push({ name: 'contents-subcategories', params: { id } }) }
      } else {
        this.$router.push({ name: 'contents-categories' })
      }
    },
    catEspecial(cat) {
      this.$swal({
        title: this.$t('specialCategoryTitle'),
        text: this.$t('specialCategoryMsg', { category_name: cat.name, category_type: this.$t(this.categoryTypes[cat.type]) }),
        icon: 'info',
        confirmButtonText: 'OK',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      }).then(() => {

      }).catch(() => {

      })
    },
    getCountryIso() {
      this.overlay = true
      let idsSelect = []
      if (this.selectedCountries.length !== 0) {
        idsSelect = this.selectedCountries.map(objeto => objeto.node.id)
      }
      axios
        .post('', {
          query: `
          query {
            isoCountry(  
              first:${this.perPage},
              offset:${this.perPage * (this.currentPage - 1)},
              name: "${this.searchCountries}",
              excludeIds:"${idsSelect}"
              ) {
              totalCount
              edgeCount
              edges {
                node {
                  id
                  isoCode
                  name
                }
              }
            }
          }
        `,
        })
        .then(response => {
          messageError(response, this)
          this.isoCountry = response.data.data.isoCountry.edges
          this.rows = response.data.data.isoCountry.totalCount
          this.overlay = false
        }).catch(() => {
          this.overlay = false
        })
    },
    handleSubmit(bvModalEvt) {
      bvModalEvt.preventDefault()
      return new Promise(resolve => {
        this.$refs.categories.validate().then(success => {
          if (success) {
            resolve(true)
            if (this.idZone) {
              this.updateZone()
            } else {
              this.createZone()
            }
          }
        })
      })
    },
  },
}
</script>

<style scoped>
#FormZone .custom-dropdown {
  position: relative;
  display: inline-block;
}

#FormZone .selected-item {
  cursor: pointer;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
  display: flex;
  align-items: center;
}

#FormZone .tableImage {
  margin-right: 2rem;
  border-radius: 10px;
  width: 8rem;
}

#FormZone .dropdown-options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  background-color: #fff;
  border: 1px solid #ccc;
  border-top: none;
  border-radius: 0 0 4px 4px;
  display: flex;
  flex-direction: column;
  max-height: 150px;
  overflow-y: auto;
}

#FormZone .dropdown-options>div {
  padding: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

#FormZone .dropdown-options>div:hover {
  background-color: #f2f2f2;
}

#FormZone .scroll-list {
  overflow: hidden;
  overflow-y: scroll;
  height: 500px;
}

#FormZone .required-state {
  border: 1px solid #ea5455 !important;

}
</style>
